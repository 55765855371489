
.dashComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4vh;
    padding-bottom: 6vh;
}

.dashFlex1{
    display: flex;
}

.dash1{
    height: 35vh;
    width: 55%;
    background-color: rebeccapurple;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    position: relative;
}

.dash1Cont{
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
}

.dash1Cont p{
    font-size: 18px;
}

.dash1 img{
    height: 100%;
    width: 100%;
    
}

.dash2{
    height: 35vh;
    width: 42%;
    margin-left: 3%;
}

.dChild1{
    position: relative;
    width: 35%;
    height: 16vh;
    border-radius: 10px;
    float: left;
    color: rgb(71, 82, 89);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.css-1r9i9ek{
    display: flex;
    padding: 0px;
    -webkit-box-align: stretch;
    align-items: stretch;
    height: 100%;
}

.css-1cdh7ge{
    position: absolute;
    top: 20%;
    right: 5%;
    color: white;
}

.css-1cdh7ge p{
    margin-top: 0px;
    font-size: 14px;
}

.css-1cdh7ge h5{
    margin-bottom: 0px;
}

.css-622j8h{
    display: flex;
    width: 126px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    outline: rgba(255, 255, 255, 0.2) solid 10px;
    margin: 0px 10px 0px -60px;
}



.dChild2{
    width: 60%;
    height: 16vh;
    background-color: white;
    border-radius: 10px;
    float: left;
    margin-left: 5%;
    margin-bottom: 3vh;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: white;
}


.dChild2 img{
    position: absolute;
    right: -17px;
    height: 100%;
}

.the2child2{
   margin-left: 10%;
   margin-top: 13%;
}


.dChild2 p{
    margin-bottom: 0px;
    
    
}

.dChild2 h6{
    margin-top: 0px;
    
}


.dChild3{
    width: 35%;
    height: 16vh;
    border-radius: 10px;
    float: left;
    color: rgb(71, 82, 89);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}


.dChild3 p{
    font-size: 13px;

}

.dChild3 h5{
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
}


.dChild2 p{
    font-size: 13px;

}

.dChild2 h5{
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
}

.dChild4 p{
    font-size: 13px;

}

.dChild4 h5{ 
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
   
}

.dChild1 p{
    font-size: 13px;

}

.dChild1 h5{
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
} 




.dChild4{
    width: 60%;
    height: 16vh;
    border-radius: 10px;
    float: left;
    margin-left: 5%;
    margin-bottom: 3vh;
    color: rgb(71, 82, 89);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: white; 
}

.css-xjuj3x{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 18px 24px;
    z-index: 2;
    position: relative;
}

.css-o2tasi{
    content: "";
    position: absolute;
    top: -35px;
    left: -55px;
    width: 185px;
    height: 185px;
    background-color: rgb(0, 196, 180);
    border-radius: 50%;
}


.dash3{
    width: 27%;
    height: 43vh;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-top: 5vh;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.dash3 img{
    width: 100%;
    bottom: -4px;
    position: absolute;
}

.dash4{
    width: 42%;
    height: 43vh;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-top: 5vh;
    border-radius: 10px;
    margin-left: 3%;
    margin-right: 3%;
    padding: 20px;
}

.dash5{
    width: 25%;
    height: 43vh;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-top: 5vh;
    border-radius: 10px;
    background-image: linear-gradient(rgb(132, 60, 246), rgb(56, 184, 242));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dashDateCont{
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6f6f6f;
}


.dash4Top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dash4Top p{
    margin: 0;
    background-color: orange;
    border-radius: 20px;
    padding: 3px 15px;
    color: white;
    font-size: 13px;
}

.dash3 hr{
    width: 50px;
    text-align: center;
    border-color: green;
    color: green;
    height: 4px;
    margin-top: 10%;
}

.dash3 h6{
    text-align: center;
}

.dash3 p{
    text-align: center;
    font-size: 13px;
    margin-left: 12%;
    margin-right: 12%;
}


.instructorPl{
    height: 70px;
    width: 70px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 30%;
    left: 45%;
    overflow: hidden;
}

.instructorPl img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.instructrDtls{
    position: absolute;
    right: 10%;
    z-index: 111;
    text-align: right;
}

.instructrDtls h6{
    margin-bottom: 0px;
}

.instructrDtls p{
    font-size: 13px;
    margin-top: 0px;
}

.dashMIcons{
    font-size: 27px;
    position: absolute;
    left: 7%;
}


.timeTable td{
    font-size: 13px;
}

.timeTable th{
    font-size: 14px;
    color: #6f6f6f;
}


/* ACADEMIC COMPONENT */

.academicComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4vh;
}

.courseContainer{
    margin-top: 5vh;
}

.courses{
    height: fit-content;
    width: 35%;
    background-color: white;
    border-radius: 10px;
    float: left;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    cursor: pointer;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
}

.coursesTwo{
    height: fit-content;
    width: 35%;
    background-color: white;
    border-radius: 10px;
    float: left;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    cursor: pointer;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
}

.courses span{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: greenyellow;
    font-size: 13px;
    padding: 3px 15px;
    border-radius: 20px;
}

.courseTop{
    background-color: orange;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
}

.courseTop2{
    background-color: orange;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;

}

.courseTop h5{
    color: white;
    margin-top: 20px;
}

.courseTop img{
    height: 23vh;
}


.courseTop2 img{
    height: 13vh;
}


.courses button{
    background-color: royalblue;
    color: white;
    font-size: 13px;
    border: 1px royalblue solid;
    padding: 6px 20px;
    border-radius: 20px;
}

.courses p{
    font-size: 13px;
    margin-left: 10%;
    margin-right: 10%;
}

.courseOutlineBtn{
    margin-left: 10px;
}


/* TEAMS COMPONENT */

.teamsComponent{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4vh;
   
}

.teamsContainer{
    margin-top: 5vh;

}

.teamsDiv{
    height: 13vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    display: flex;
    align-items: center;
    padding-left: 3%;
    position: relative;
    margin-bottom: 2vh;
}

/*  */


.channelItem{
    background-color: white;
    width: 47%; 
    height: max-content;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-bottom: 3.5vh;
    margin-right: 3%;
    position: relative;
    overflow: hidden;
    float: left;
    cursor: pointer;
}


.channelItemTop{
    height: 200px;
    width: 100%;
    background-image: url(../Images/channelone.jpg);
    background-size: cover;
}

.channelItemCont{
    padding: 10px 20px;
}

.channelItemCont p{
    font-size: 13px;
}

.profilePlaceHolderCont{
    display: flex;
    margin-bottom: 10px;
}

.profileChannel{
    height: 50px;
    width: 50px;
    background-color: red;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
}

.profileChannel img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.profileChannel2{
    height: 50px;
    width: 50px;
    background-color: rgb(168, 186, 241);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileChannel2 span{
    font-size: 12px;
    color: black;
    margin: 0;
    padding: 0;
}

.teamsItem{
    background-color: white;
    width: 30%;
    height: 50vh;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-bottom: 3.5vh;
    margin-right: 3%;
    position: relative;
    overflow: hidden;
    float: left;
}

.teamsItemBottomCont{
    position: absolute;
    bottom: 0;
    display: flex;
    border-top: 1px solid #c7c4c4;
    width: 100%;
}

.teamsItemChatbtn{
    height: 50px;
    border-right: #c7c4c4 1px solid;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.teamsItemChatbtn p{
    margin: 0;
    font-size: 14px;
}

.teamsItemChatbtn:hover{
    background-color: royalblue;
    color: white;
}


.teamsItemChatbtn1{
    height: 50px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.teamsItemChatbtn1 p{
    margin: 0;
    font-size: 14px;
}

.teamsItemChatbtn1:hover{
    background-color: royalblue;
    color: white;
}



.teamsItemProfilecont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 20%;
}
.teamsItemProfilecont h6{
    margin: 0;
}

.teamsItemProfilecont span{
    color: #6f6f6f;
    font-size: 13px;
    text-decoration: none;
}

.allStudentsLocationIcon{
    font-size: 10px;
}

/* for instructor start */

.teamsItem2{
    background-color: royalblue;
    width: 40%;
    height: max-content;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    margin-bottom: 3.5vh;
    margin-right: 3%;
    position: relative;
    overflow: hidden;
    float: left;
    background-image: url(../Images/back-image2.png);
    background-repeat: no-repeat;
    background-size: 140px;
    background-position: right bottom;
}

.roomTopdesc{
    display: flex;
    align-items: center;
    color: white;
    padding: 0;
}


.roomTimeClasses{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}


.roomTimeClassesH{
    font-size: 12px;
    color: rgb(168, 186, 241);
    margin-bottom: 0px;
    padding-bottom: 0px;
    
}


.roomTimeClassesH2{
    font-size: 12px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: rgb(168, 186, 241);
}


.defaultAvatarCont{
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.defaultAvatar{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.roomTopContent{
    margin-left: 20px;
}

.teamsItemProfilecont2{
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}


.joinMyClass{
    background-color: orange;
    color: white;
    font-size: 12px;
    border: 2px orange solid;
    border-radius: 20px;
    padding: 2px 13px;
}


.joinMyClass:hover{
    background-color: transparent;
    color: orange;
}

.roomTopdesc p{
    font-size: 13px;
    margin: 0px;
    padding-top: 0px;
    
}


.roomTimeClassesP{
    margin-top: 0px;
    padding-top: 0px;
    font-size: 12px;
    color: white;
}

.roomTimeClassesP2{
    margin-top: 0px;
    padding-top: 0px;
    font-size: 12px;
}


.teamsItemBottomCont2{
    position: absolute;
    bottom: 0;
    border-top: 1px solid #c7c4c4;
    width: 100%;
}

.teamsItemChatbtn2{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.teamsItemChatbtn2 p{
    margin: 0;
    font-size: 14px;
}

.teamsItemChatbtn2:hover{
    background-color: royalblue;
    color: white;
}

/* end */

.teamsUserCont{
    background-color: red;
    height: 80px;
    width: 80px;
    border-radius: 50%;   
    margin-right: 15px;
    margin-bottom: 5%;
    overflow: hidden;
    background-image: url(../Images/defprofile.png);
    background-size: cover;
}

.teamsUserCont img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.teamsItemsProfileico{
    display: flex;
    margin-top: 5px;
}

.teamsItemsProfileico img{
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
}

.startOnlSessBtn{
    background-color: royalblue;
    color: white;
    font-size: 13px;
    border: 1px solid royalblue;
    border-radius: 20px;
    padding: 5px 20px;
}

.startOnlSessBtn2{
    background-color: royalblue;
    color: white;
    font-size: 13px;
    border: 1px solid royalblue;
    border-radius: 20px;
    padding: 5px 20px;
    display: none;
}

.teamsTopBar{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tHide2{
    display: none;
}


.liveSessionIcon{
    margin-right: 5px;
}


.chatIcon{
    height: 25px;
    cursor: pointer;
}

.tNamePC{
    margin-left: 2%;
}

.tNamePC h6{
    margin-bottom: 0;
    margin-top: 0;
}

.tNamePC a{
    margin-bottom: 0;
    font-size: 14px;
    color: #6f6f6f;
    text-decoration: none;
}


.tCallToActBtn{
    position: absolute;
    right: 5%;
}

.tCallToActBtn button{
    margin-left: 20px;
    background-color: orange;
    color: white;
    font-size: 14px;
    padding: 5px 20px;
    border: 1px orange solid;
    border-radius: 5px;
}

.tCourseName{
    position: absolute;
    left: 45%;
    margin-bottom: 0;
}



/* ASS TASKS COMP */

.assTasksComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}


.assContainer{
    margin-top: 5vh;
}

.assTop{
    display: flex;
    justify-content: space-between;
}

.taskCont1{
    height: 15vh;
    width: 31%;
    background-color: orangered;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    display: flex;
    align-items: center;
}

.assTop img{
    height: 50%;
    margin-left: 8%;
    margin-right: 8%;
}

.assTop p{
    margin: 0;
    color: white;
}

.assTop h3{
    margin: 0;
    color: white;
}


.taskCont2{
    height: 15vh;
    width: 31%;
    background-color: royalblue;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    display: flex;
    align-items: center;
}

.taskCont3{
    height: 15vh;
    width: 31%;
    background-color: orange;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    display: flex;
    align-items: center;
}


.theAssMainCont{
    display: flex;
    margin-top: 5vh;
    justify-content: space-between;
    margin-bottom: 5vh;
}


.assInstructorCont{
    width: 31%;
    height: 70vh;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.taskItemsCont{
    height: 70vh;
    width: 65%;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    padding: 20px;
}

.assInstructorCont img{
    width: 100%;
    
}


.assInstrOpacity{
    opacity: 0.5;
    background-color: black;
    top: 0;
    width: 100%;
    height: 43%;
    position: absolute;
}


.assInstrProf{
    top: 10%;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.assInstrProfImg{
    height: 80px;
    width: 80px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    border: 2px white solid;
}

.assInstrProf h6{
    color: white;
    margin-bottom: 0;
    margin-top: 2px;
}

.assInstrProf a{
    color: white;
    font-size: 14px;
}


.assAboutIntr{
    padding: 20px;
}

.assAboutIntr p{
    font-size: 14px;
    color: #6f6f6f;
}



/* STARTUP COMPONENT */

.startupComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}

.startupContainer{
    margin-top: 5vh;
}

.startupTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.startupTop button{
    background-color: royalblue;
    border: 1px royalblue solid;
    font-size: 14px;
    color: white;
    padding: 5px 20px;
    border-radius: 20px;
}

/* ADD STATUP */
.addStartupCards{
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 5vh;
}

.addStartupCards p{
    color: #6f6f6f;
    font-size: 14px;
}


/* CLIENTS COMP */
.clientsComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}

.clientsTop{
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
}

.clientLeft{
    width: 48%;
}

.allClientsList{
    margin-top: 10vh;
    clear: both;
}

.clientRight{
    width: 48%;
}

.clientAlloc{
    width: 100%;
    height: 38vh;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    padding-top: 15px;
    position: relative;
}

.clientAlloc h6{
    margin-left: 20px;  
}

.clientAlloc h4{
    margin-left: 20px;
    margin-bottom: 0;
}

.clientAlloc P{
    margin-left: 20px;  
    font-size: 14px;
    margin-top: 0;
    color: #6f6f6f;
}

.clientAlloc ul{
    padding-left: 20px;
}

.clientAlloc li{
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;
    font-size: 14px;
    color: #6f6f6f;
}

.clientAlloc span{
    color: black;
    
}


.clientAllocIconCont{
    position: absolute;
    right: 10%;
    top: 30%;

}


.clientAllocIconCont img{
    height: 100px;
}

.clientAllocIconCont button{
    background-color: royalblue;
    color: white;
    border: 1px royalblue solid;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 5px;
    margin-top: 20px;
}

.cliRight1{
    height: 15vh;
    width: 47%;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    float: left;
    margin-right: 6%;
    float: left;
    display: flex;
    overflow: hidden;
}

.cliRight2{
    height: 15vh;
    width: 47%;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    float: left;
    display: flex;
    overflow: hidden;
}


.clirightCont{
    margin-left: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.clirightCont p{
    font-size: 13px;
}

.clirightCont p{
    margin: 0;
}

.clirightCont h4{
    margin: 0;
}


.cliRoundIconCont{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 2s;
}

.cliRoundIcon{
    height: 55px;
    width: 55px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.cliRoundIcon1{
    height: 55px;
    width: 55px;
    background-color: royalblue;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.cliRight2:hover .cliRoundIcon{
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: all 1s;
   
}

.cliRight2:not(:hover) .cliRoundIcon{
    transition: all 1s;
} 


.cliRight1:hover .cliRoundIcon1{
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: all 1s;
   
}

.cliRight1:not(:hover) .cliRoundIcon1{
    transition: all 1s;
} 



.cliRight3{
    height: 19vh;
    width: 100%;
    background-image: url(../Images/cities-bg.png);
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    float: left;
    margin-top: 4vh;
    background-size: cover;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    justify-content: space-between;
}


.cliRight3 p{
    font-size:13px;
    color: white;
    margin-bottom: 0px;
    margin-top:0px;
}

.cliRight3 h2{
    color: white;
    margin-top: 1px;
    margin-bottom: 1px;
}

.cloidIcoClient{
    font-size: 50px;
    margin-left: 10%;
}


/* CLASS REPORT COMPONENT */

.darasaComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}

/* FEE REPORTS COMPONENT */

.feeComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4vh;
}

.feeTopDiv{
    height: 20vh;
    background-color: royalblue;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    color: white;
}

.feeItemsCont{
    display: flex;
    justify-content: space-between;
}
/* .feeItem{
    margin-right: 20px;
    float: left;
} */

.feeTopDiv h6{
    margin-bottom: 20px;
}


.feeTransactionHeading{
    margin-top: 40px;
    margin-bottom: 40px;
}

.feeItemTop{
    display: flex;
    align-items: center;
}

.feeItemTop p{
    margin-bottom: 0;
    margin-left: 10px;
}


.feeItemP{
    font-size: 14px;
}

.feelineGraph{
    height:fit-content;
    background-color: white;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem; 
}


.theFeeLineGraph{
    height: 10vh;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.feeTableCont{
    background-color: white;
    padding: 30px;
    margin-bottom: 6vh;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
}

.feeTable td{
    font-size: 14px;
}

.feeTable th{
    font-size: 14px;
    color: #6f6f6f;
}

.feeButton{
    font-size: 14px;
}

/* AI ASSISTANT COMPONENT */

.AiComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}

/* PROFILE COMPONENT */

.profileComp{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2vh;
}

.profileTop{
    position: relative;
}

.coverPhotoCont{
    height: 40vh;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background-image: url(../Images/default-cover.gif);
    background-position: center;
    background-size: cover;
    overflow: hidden;
}


.coverPhotoCont img{
    object-fit: cover; 
    height: 100%;
    width: 100%;
    object-position: center;
}


.profilePictureCont{
    height: 200px;
    width: 200px;
    background-image: url(../Images/defprofile.png);
    border-radius: 50%;
    position: absolute;
    top: 25vh;
    left: 5%;
    border: 4px rgb(245, 247, 250) solid;
    overflow: hidden;
    background-size: cover;
}

.profilePictureCont img{
    object-fit: cover; 
    height: 100%;
    width: 100%;
    object-position: center;
}


.profileInfoCont{
    position: absolute;
    top: 42vh;
    left: 28%;
    display: flex;
    justify-content: space-between;
    width: 72%;
}

.profileLocationIcon{
    font-size: 13px;
    
}

.profileInfoCont p{
    color: #6f6f6f;
    margin: 0;
}

.profileInfoCont h3{
    margin-bottom: 0px;
}

.profileInfoCont button{
    background-color: royalblue;
    height: fit-content;
    color: white;
    border: royalblue 1px solid;
    font-size: 13px;
    padding: 5px 20px;
    border-radius: 20px;
    margin-right: 5%;
}

.editAccountMobile{
    display: none;
}

.profileBody{
    display: flex;
    margin-top: 19vh;
    margin-bottom: 5vh;
   
}

.EditProfileBody{
    margin-top: 19vh;
    margin-bottom: 5vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    padding: 20px;
}

.EditProfileBody2{
    margin-top: 4vh;
    margin-bottom: 5vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    padding: 20px;
}

.EditProfileBody2 label{
    font-size: 14px;
    color: #6f6f6f;
}

.EditProfileBody p{
    font-size: 14px;
    color: #6f6f6f;
}

.profieAboutBody{
    width: 30%;
    /* height: 40vh; */
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    overflow: hidden;
    padding-bottom: 14px;
}




.profieAboutBodyTop{
    height: 6vh;
    background-color: rgba(115, 82, 199, 0.15);
    display: flex;
    align-items: center;
}

.profieAboutBodyTop h6{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
}

.profieAboutBody p{
    font-size: 14px;
    margin: 20px;
}


.profieAboutBody hr{
    margin-left: 20px;
    margin-right: 20px;
}

.clTitAbBody{
    margin-left: 20px;
    font-size: 15px;
    margin-bottom: 20px;
}

.profileContactCard{
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.profileContactCard p{
    margin-top: 0;
    margin-bottom: 0;
}


.profileContactCardTitle{
    color: #6f6f6f;
}

.profileOverview{
    background-color: white;
    width: 65%;
    margin-left: 5%;
    padding: 20px;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    border-radius: 10px;
    
}

.profileTabs{
    align-items: right;
}


.updateMyProfile{
    padding: 20px;
}

.updateMyProfile label{
    margin-bottom: 2px;
    color: #6f6f6f;
    font-size: 14px;
}



.roundProfileIconCont{
    height: 50px;
    width: 50px;
    border: cornflowerblue 1px solid;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 10px;

}

.overviewProfie{
    position: relative;
    height: fit-content;
    background-color: white;
}

.profileGithubCont{
    position: absolute;
    bottom: 0;
    right: 0;
}


.overviewProfie h6{
    color: #6f6f6f;
    margin-top: 30px;
    
}

.overviewProfie p{
    font-size: 14px;
}


.profileOverviewCard{
    display: flex;
    float: left;
    margin-top: 20px;
    margin-right: 20px;
}

.profileOverviewCard p{
    margin: 0;
}


.githubIcon{
    height: 20px;
    margin-right: 10px;
}

.overviewProfie a{
    color: #6f6f6f;
    text-decoration: none;
}


.profileOverviewIcon{
    color: cornflowerblue;
    font-size: 20px;
}


.profileCerts{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #6f6f6f;
}


/* EDIT PROFILE */


.EditProfileBody button{
    background-color: royalblue;
    color: white;
    border: 1px royalblue solid;
    padding-left: 20px;
    padding-right: 20px;

}




/* ON SESSION */

.onSessionCont{
    background-color: #eff3f6;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.clearFixAll{
    clear: both;
    margin-top: 50px;
}



/* AI CHAT */

.AiComp{
    position: relative;
    width: 100%;
    height: 88vh;

}


.aiChatCont{
    position: absolute;
    bottom: 25px;
    width: 90%;
}


.aiChatInput{
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px #b7b5b5 solid;
    font-size: 13px;
}

.aiChatInput:focus{
    outline: 1px #b7b5b5 solid;
}

.aiFooterText{
    position: absolute;
    bottom: 10px;
    width: 90%;
}



.aiChatBtn{
    position: absolute;
    right: 7px;
    top: 5px;
    background-color:royalblue;
    border: none;
    font-size: 13px;
    color: white;
    padding: 7px 15px;
    border-radius: 20px;

}


/* INSTRUCTOR */

.instructorComponent{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
}

.mySkillsTab{
    display: flex;
    justify-content: space-between;
    border-bottom: #eff3f6 1px solid;
    padding-bottom: 10px;
    padding-top: 10px;
}

.mySkillsTab p{
    margin: 0;
}

.mySkillsTab2{
    text-align: right;
}

.mySkillsTabpp{
    color: #6f6f6f;
}



.searchContainer{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: end;
}

.searchContainer button{
    background-color: royalblue;
    font-size: 12px;
    padding: 10px 20px;
    border: none;
    color: white;
    margin-left: 10px;
}

td{
    font-size: 13px;
}
.nav-link{
    margin-bottom: 10px;
}

.year-buttons{
    display: flex;
    margin-top: 20px;
    justify-content: center;
}


.year-button {
    margin-left: 5px;
    margin-right: 5px;
}


.spinner-container{
    width: 100%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
}

/* new stuff */


.dChild22{ 
    width: 30%;
    height: 16vh;
    background-color: white;
    border-radius: 10px;
    float: left;
    margin-bottom: 3vh;
    box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: white;
}

.dChild22 p{
    font-size: 13px;
    margin-bottom: 0px;

}

.dChild22 h5{
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
}

.paymentsDivContainer{
    display: flex;
    justify-content: space-between;
}


.paymentTopCont{
    display: flex;
    justify-content: space-between; 
}


.weirdAlert{
    font-size: 13px; 
}


.materialComp{
    border: #6f6f6f 1px solid;
    width: 30%;
    border-radius: 5px;
    float: left;
    margin-left: 10px; 
    margin-right: 10px; 
    margin-bottom: 20px; 
}

.materialCompBottom{
    border-top: #6f6f6f 1px solid; 
    padding: 10px; 
}

.materialCompBottom p{
    font-size: 12px;
}


.materialMediaContainer{
    height: 100%; 
    height: 40vh;
    background-color: rebeccapurple;
}


.materialCompBottom button{
    background-color: royalblue;
    font-size: 11px;
    border: 1px solid royalblue; 
    color: white;
    padding: 2px 5px;
    border-radius: 5px; 
    margin-left: 10px;
}

.materialDownloadContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.materialMediaContainer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.materialMediaContainer video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}