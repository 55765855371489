/* global stuff */
.lightGray{
  color: #d6d5d5;
}

/* home */
.Hero{
    height: 100vh;
    width: 100%;
    position: relative;
}

.theVideo{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.opacity{
  height: 100vh;
  width: 100%;
  background-color: #1c1c1c;
  opacity: 0.5;
  position: absolute;
  top: 0;
}

.xContent{
  position: absolute;
  top: 35vh;
  left: 10%;
  width: 90%;
}

.xContent h1{
  font-weight: bold;
  color: white;
  width: 30%;
}

.xContent p{
  color: #d6d5d5d0;
  width: 30%;
}

.countryCont{
  padding: 2px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-right: 5px;
}

.countryCont img{
  height: 30px;
  
}

.signInbtnn{
  background-color: #ff214f;
  border: 2px solid #ff214f;
  color: white;
  margin-top: 20px;
  padding: 5px 30px;
  border-radius: 30px;
}

.signInbtnn:hover{
  background-color: transparent;
  border: 2px solid #ff214f;
  color: #ff214f ;
}

.signInbtProgress{
  border-radius: 30px;
  background-color: #ff214f;
}


.signInbtn{
  background-color: #ff214f;
  border: 2px solid #ff214f;
  color: white;
  margin-top: 20px;
  padding: 5px 30px;
  border-radius: 30px;
}

.signInbtn:hover{
  background-color: transparent;
  border: 2px solid #ff214f;
  
}


.scrollDown{
  height: 45px;
  border-radius: 50%;
  width: 45px;
  background-color: royalblue;
  position: absolute;
  bottom: 7vh;
  left: 47%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}


.theScroll{
  margin-right: 2px;
}


/* Navbar */

.navBar{
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  padding-top: 1.5vh;
  align-items: center;
}

.logo{
  height: 7vh;
}

.listItems{
  display: inline;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 13px;
  cursor: pointer;
}

.listItems a{
  color: white;
  text-decoration: none;
}

.listItems a:hover{
  color:#cfcbcb;
}


.signIn{
  padding: 5px 30px;
  border: 2px solid white;
  background-color: white;
  border-radius: 20px;
  font-size: 13px;
}

.signIn:hover{
  background-color: transparent;
  color: white;
}

.theSign{
  font-size: 10px;
}

.theGrad{
  font-size: 11px;
}

.menu{
  display: flex;
  align-items: center;
}


.theBarsIcon{
  color: white;
  cursor: pointer;
  display: none;
}


.dropDownComponent{
 padding-top: 20px;

}



/*  */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1c1c1c;
  min-width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 15px;
}

.dropdown-content a {
  color: #6f6f6f;
  padding-bottom: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: white;
  font-size: 14px;
}

.dropdown:hover .dropdown-content {
  display: block;

}

.dropdown:hover .dropbtn {background-color: #3e8e41;}


/* About Sec */

.aboutSec{
  background-color: #ededed;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  padding-bottom: 100px;
}

.abSec1{
width: 30%;
margin-right: 5%;

}

.abSec1 h6{
  font-size: 13px;
  color: #ff214f;

}

.aboutSec h3{
  font-weight: bolder;
}
.abSec2{
  width: 25%;
  margin-right: 5%;
}

.abSec3{
  width: 25%;
}


.boy4{
  width: 100%;
  border-radius: 5px;

}


.theLine{
  width: 20px;
  border-top: none;
  border-bottom: #ff214f solid 3px;
  border-left: none;
  border-right: none;
  margin-bottom: 10px;
  margin-right: 10px;
}


.signUpbtn{
  background-color: royalblue;
  border: 2px royalblue solid;
  padding: 7px 40px;
  color: white;
  text-decoration: none;
}

.signUpbtn:hover{
  background-color: transparent;
  color: royalblue;
}

.lineCont{
  display: flex;
  align-items: center;
  
}

.lineCont p{
  font-weight: bold;


}

.aboutSec p{
  color:#6f6f6f;
  font-size: 15px;
}

/* section 3 */

.section3{
  height: 60vh;
  background-image: url(./Images/bg3.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.opacity2{
  height: 100%;
  width: 100%;
  background-color: #1c1c1c;
  opacity: 0.75;
  position: absolute;
  top: 0;
}

.section3Cont{
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.section3Cont h2{
  color: #cfcbcb;
}

.section3Cont a{
  background-color: #ff214f;
  color: white;
  border: 2px solid #ff214f;
  font-size: 14px;
  padding: 7px 50px;
  border-radius: 20px;
  text-decoration: none;
}

.section3Cont a:hover{
  background-color: transparent;
  color: #ff214f;
}



/* Section 4 */

.section4{
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
}

.section4 p{
  color:#6f6f6f;
  font-size: 15px;
}
.section4 h5{
  color: #ff214f;
}

.section4 a{
  background-color: royalblue;
  border: 2px solid royalblue;
  color: white;
  padding: 7px 30px;
  text-decoration: none;
}

.section4 a:hover{
  background-color: transparent;
  color: royalblue;
}

.sec4a{
  width: 50%;
  padding-left: 10%;
  padding-right: 5%;
}
.sec4b{
  width: 50%;
  padding-right: 5%;
  padding-left: 5%;
}

.sec4bDivs{
  width: 40%;
  float: left;
  margin-right: 35px;
}

.sec4bDivs img{
  width: 30%;
}


/* SEction 6 */
.section5{
  display: flex;
}

.sec5div1{
  width: 25%;
  height: 70vh;
  background-image: url(./Images/back50.jpg);
  background-size: cover;
}

.sec5div3{
  width: 25%;
  height: 70vh;
  background-image: url(./Images/upload5.jpg);
  background-size: cover;
}

.sec5div2{
  width: 25%;
  height: 70vh;
  background-color: black;
  padding: 40px;
}

.sec5div4{
  width: 25%;
  height: 70vh;
  background-color: black;
  padding: 40px;
}

.section5 p{
  font-size: 15px;
  color: #454444;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section5 h5{
  color: white;
  margin-bottom: 20px;
}

.section5 a{
  background-color: transparent;
  border: 1px white solid;
  color: white;
  font-size: 15px;
  padding: 7px 30px;
  text-decoration: none;
}

.section5 a:hover{
  background-color: white;
  color: black;
}

/* section6 */

.section6{
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.sec6div1{
  width: 50%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec6div2{
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.sec6div1A{
  background-image: url(./Images/intro.jpg);
  height: 80%;
  width: 80%;
  background-size: cover;
  border-radius: 3px;
  background-position: left;
}


.sec6div2 h5{
  color: #ff214f;
}

.sec6div2 p{
  color:#6f6f6f;
  font-size: 15px;
}

.sec6div2 a{
  width: fit-content;
  padding: 7px 30px;
  background-color: royalblue;
  color: white;
  border: 2px royalblue solid;
  text-decoration: none;
}

.sec6div2 a:hover{
  background-color: transparent;
  color: royalblue;
}


/* theFooter */

.theFooter{
  background-color: #1c1c1c;
  width: 100%;
  height: 45vh;
  padding-left: 7.5%;
  padding-right: 7.5%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.copySec{
  background-color: #141414;
  height: 17vh;
  width: 100%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 7.5%;
}

.footer1{
  width: 25%;
  border-right: 1px solid #3c3b3b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

.footer2{
  width: 25%;
  border-right: 1.5px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}


.footer3{
  width: 28%;
  border-right: 1px solid #3c3b3b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

.footer2 li{
  list-style-type: none;
  color: #6f6f6f;
  font-size: 13px;
  margin-bottom: 3px;
}

.footerLogo{
  height: 6vh;
  width:fit-content;
  margin-bottom: 15px;

}

.footer1 p{
  color: #6f6f6f;
  font-size: 13px;
}

.footer3 p{
  color: #6f6f6f;
  font-size: 13px;
  margin-left: 30px;
}

.footerIcons{
  height: 18px;
  margin-right: 15px;
}

.theFooter h6{
  font-size: 13px;
  color: white;
  margin-left: 30px;
  margin-bottom: 15px;
}

/* LOGIN */

.loginContainer{
  /* background-image: url(./Images/reviews_bg.jpg); */
  background-size: cover;

}

.loginLeft{
  height: 100vh;
  width: 60%;
  background-color: white;
  background-size: cover;
  background-position: center;
  position: relative;
}


.loginLeft video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 75%; 
  padding: 20px;
  border-radius: 0px 50px 0px 50px;
}

.loginRight{
  height: 100vh;
  width: 40%;
  padding-left: 5%;  
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginLogo{
  height: 7vh;
  width:auto;
  margin-bottom: 30px;
}

.loginRight h5{
  color: black;
}

.loginRight h6{
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: unset;
}


.loginForm{
  height: fit-content;
  font-size: 10px;
  padding-top: 10px;
}


.signupLink{
  text-align: center;
  color: royalblue;
  margin-top: 20px; 
}

.theLoginIcon{
  font-size: 13px;
  padding: 2px;
  color: #6c757d;
}

.inputLabel{
  color: #6c757d;
  font-size: 14px;
}


.loginRight p{
  text-align: right;
  font-size: 14px;
  text-decoration: none;
}

.fgPassLink{
  text-decoration: none;
}

.loginOpacity{
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.loginCont{
  height:fit-content;
  width:100%;
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.loginDots{
  background-color: rgb(184, 183, 183);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
}

.loginCont p{
  margin-top: 0px;
  margin-bottom: 10px;
}

.loginCont h3{
  margin: 0;
}



.loginPils{
  width: 30px;
  height: 10px;
  background-color: white;
  border-radius: 10px;
  margin-right: 3px;
  margin-left: 3px;
}

/* RESET PASSWORD */

.resetleft{
  height: 100vh;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.resetRight{
  height: 100vh;
  width: 60%;
  background-image: url(./Images/mercy.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

.resetleft p{
  text-align: right;
}


/* DASHBOARD */

/* Dashnav */
.dashNav{
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2vh;
  padding-top: 3vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 199;
  background-color: rgb(245, 247, 250);
}


/* Dashnav */
.dashNavTwo{
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2vh;
  padding-top: 3vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 199;
  background-image: url(./Images/topbanner.png);
  background-size: cover;
}


.mainContainer{
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: rgb(245, 247, 250);
  overflow: hidden;
}
/* leftNav */


.leftNav{
  width: 20%;
  height: 100%;
  background-color: white;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  padding-top: 3vh;
  padding-bottom: 6vh;
  overflow: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  z-index: 200;
}

.leftNav::-webkit-scrollbar {
  display: none;
}







/* DASHBOARD */
.theMainContainer{
  height: 100vh;
  width: 80%;
  overflow-y: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.searchDashCont{
  width: 30%;
  position: relative;
}

.dashSearchIcon{
  color: #6f6f6f;
  position: absolute;
  top: 30%;
  left: 5%;
}

.dashNavTwo input{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 20px;
  border: none;
  font-size: 13px;
  color:#cfcbcb;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}


.dashNav input{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 20px;
  border: none;
  font-size: 13px;
  color:#cfcbcb;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
}

.dashNavTwo input:focus{
  outline: none;
}

.dashNav input:focus{
  outline: none;
}

.chatCont{
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.notifCounter{
  position: absolute;
  top: -7px;
  font-size: 13px;
  height: 20px;
  width: 20px;
  background-color: rgb(115, 82, 199);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  right: -3px;
}


.notifCounter2{
  position: absolute;
  top: -7px;
  font-size: 13px;
  height: 20px;
  width: 20px;
  background-color: tomato;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  right: -3px;
}

.NotifCont{
  height: 45px;
  width: 45px;
  background-color: white;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profCont{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
  overflow: hidden;
  cursor: pointer;
}


.profCont img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.allNotifCont{
  display: flex;
}

/* leftNavLinks */
.laftNavIcon{
  color: #6f6f6f;
  margin-right: 10px;

}

/* .dashNavIco{
  outline: none;
} */

.mobileMenuTitles{
  margin-left: 12%;
  color: #a7a6a6;
  margin-top: 10px;
}

.leftLinkNotif{
  background-color: #04AA6D;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 12px;
  padding: 0;
  margin-left: 10%;
}

.dropdownMyCont{
  justify-content: space-between;
}

.leftLinkNotif2{
  padding-right: 10px;
}

.submenu{
  margin-left: 15%;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.leftLinkCont{
  width: 90%;
  padding-left: 12%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 20px 20px 0px;
  font-weight: 450;
  font-size: 14px;
}



.leftNavLinks{
  text-decoration: none;
  color: #141414;
  width: 90%;
  /* height: 100%; */
}


.leftLinkCont:hover{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199);
}

.activeleftNavLinks{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199);
}



.leftNavLinks:hover .laftNavIcon{
  color: rgb(115, 82, 199);
}


.leftNav p{
  color: #a7a6a6;
  font-weight: lighter;
  font-size: 13px;
  padding-left: 12%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashNoticIcon{
  color: #141414;
}

.dash5 h6{
  margin-top: 10px;
  color: white;
}

.dash5 p{
  color: white;
}


.dashMenuIcon{
  display: none;
}


/* COMPLETE */

.completePage{
  height: 100vh;
  width: 100%;
  background-color: #f3f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completeLogo{
  height: 50px;
  width: fit-content;
  margin-bottom: 40px;
}


.myCard{
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.myCard h5{
  color: #04AA6D;
}

.myCard p{
  font-size: 14px;
}


.completeCheck{
  color: #04AA6D;
  font-size: 70px;
  margin-bottom: 20px;
  margin-top: 20px;
}


.completeLink{
  font-size: 14px;

}


.active{
  background-color: rgba(115, 82, 199, 0.15);
  border-left: 3px solid rgb(115, 82, 199);
  padding-left: 11%;
  color: rgb(115, 82, 199); 

}



/* .activelaftNavIcon{
  color: rgb(115, 82, 199);
} */


.searchResults{
  background-color: white;
  width: 27%;
  height: 40vh;
  position: absolute;
  top: 10vh;
  -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 5px;
  overflow: scroll;
}

.searchResultsStud{
  
}

.resultItemDiv{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.resultItemDiv img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.resultItem{
  display: flex;
  align-items: center;
  margin: 10px;
  border-bottom: 1px solid rgb(236, 232, 232);
  padding-bottom: 10px;
  cursor: pointer;
}

.resultItem p{
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 10px;
}
.statusResult{
  font-size: 12px;
}


/* view student */

.viewstudentButtonCont{
  font-size: 12px;
  display: flex;

}

.viewBtn1{
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid rgba(83, 105, 248, .15);
  background-color: rgba(83, 105, 248, .15);
  color: #5369f8;
}

.viewBtn2{
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid ;
  background-color: rgba(255, 92, 117, .15);
  color: #ff5c75;
  border-color: rgba(255, 92, 117, .15);
  margin-left: 7px;
}

.viewBtn3{
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid ;
  background-color: rgba(67, 211, 158, .15);
  color: #43d39e;
  border-color: rgba(67, 211, 158, .15);
  margin-left: 7px;
}

.viewBtn4{ 
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid ;
  background-color: rgba(30, 33, 57, .15);
  color: #1e2139;
  border-color: rgba(30, 33, 57, .15);
  margin-left: 7px;
}



.viewStudentTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewStudentTop2{
  /* -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 5px;
  margin-top: 5vh;
  background-color: white; */
  /* padding: 20px; */
  margin-top: 24px;
  -webkit-box-shadow: 0 .05rem .08rem rgba(75, 75, 90, .075);
  box-shadow: 0 .05rem .08rem rgba(75, 75, 90, .075);
  border-radius: .25rem;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: .5rem;
  margin-bottom: 24px;
}

.viewStudentTop2 h6{
  padding: 20px;
  font-weight: bold;
}

.viewStudentTop21{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(234, 232, 232);
  padding: 20px;
}

.viewTopFontAsw{
  font-size: 30px;
  color: gray;
  margin-right: 20px;
}

.viewTopIcons{
  display: flex;
  align-items: center;
}

.viewTopIcons2 p{
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.viewTopIcons2 h4{
  padding: 0;
  margin: 0;
}

.viewStudentAboutLeft{
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: .5rem;
  margin-bottom: 24px;
  -webkit-box-shadow: 0 .05rem .01rem rgba(75, 75, 90, .075);
  box-shadow: 0 .05rem .01rem rgba(75, 75, 90, .075);
  border-radius: .25rem;
  height: 100%;
  width: 57%;
  margin-right: 3%;
  padding: 20px;
}

.viewStudentAboutRight{
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: .5rem;
  margin-bottom: 24px;
  -webkit-box-shadow: 0 .05rem .01rem rgba(75, 75, 90, .075);
  box-shadow: 0 .05rem .01rem rgba(75, 75, 90, .075);
  border-radius: .25rem;
  height: 100%;
  width: 40%;
  padding: 20px;
}


.viewStudentAbout{
  display: flex;
  width: 100%;
  height: 70vh; 
}

.aboutTextInfo{
  font-size: 12px;
  color: #6c757d !important;
}


.viewAboutFw{
  color: #ff5c75!important;
}

.statAbout{
  font-size: 13px;
  margin: 0;
  padding: 0; 
}


.ststAbout2{
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.statsCont{
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.classmateItem{
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.classmateItemImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;

}

.classmateItemImg img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  
}

.classmatesList{
  display: flex;
}
.classmatesList p{
  font-size: 11px;
}

.viewProfPicCont{
  height: 100px;
  width: 100px;
  border: 2px solid red;
  border-radius: 50%;
  margin-bottom: 10px; 
  overflow: hidden;
}

.viewProfPicCont img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.viewProfPic{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(231, 231, 231);
  padding-bottom: 20px; 
}


.viewProfh6{
  margin: 0;
  padding: 0;
}

.viewProfEmail{
  margin: 0;
  padding: 0;
  color: royalblue;
  font-size: 13px;
}

.viewProfCount{
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.viewAboutPic2{
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.viewAboutPic2 h6{
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.viewAboutPic2 p{
  font-size: 12px;
  color: #6c757d !important;
  margin: 0;
  padding: 0;
}

.dashnavLogo{
  height: 6vh;
  margin-bottom: 8vh;
  top: 3vh;
  left: 20px;
}


.logoContainer{
  background-color: white;
  position: sticky;
  top: 0;
  padding-left: 20px;

}

.viewStudentSpan{
  text-decoration: underline;
  cursor: pointer;
  color: #5369f8;
}

.badbeUsertype{
  font-size: 12px;
  color: white;
  padding: 5px 20px;
  border-radius: 20px;
} 

