@media screen and (max-width:768px) {

    .dashFlex1{
        display: flex;
        flex-direction: column;
    }

    .dash1{
        width: 100% !important; 
        height: 25vh !important;
        margin-bottom: 3vh;  
    }

    .dash1Cont p{
        margin: 0;
    }

    .dash1Cont h4{
        margin: 0;
    }

    .dash2 p{
        font-size: 13px;
    }

    .dash2{
        height: 35vh;
        width: 100% !important; 
        margin-left: 0 !important; 
    }

    .dChild1{
        width: 41%;
    }

    .dChild2{
        width: 55%;
        margin-left: 3%;
        margin-bottom: 1.5vh;
    }

    .dChild3{
        width: 41%;
    }

    .dChild4{
        width: 55%;
        height: 16vh;
        border-radius: 10px;
        float: left;
        margin-left: 3%;
        margin-bottom: 0;
    }

    .css-o2tasi{
        content: "";
        position: absolute;
        top: -35px;
        left: -60px;
        width: 155px;
        height: 185px;
        background-color: rgb(0, 196, 180);
        border-radius: 50%;
    }


    .dash3{
        width: 100%;
        height: 49vh;
    }


    .dash4{
        width: 100%;
        height: 50vh;
        margin-top: 3vh;
        border-radius: 10px;
        margin-left: 0;
        margin-right: 0;

    }

    .dash5{
        width: 100%;
        height: 43vh;
        background-color: white;
        box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
        margin-top: 3vh;
    }

    .dashComp{
        padding-top: 2vh;
    }



    /* COURSES */

    .courses{
        width: 100%;
    }



    /* TEAMS */

    .teamsItem{
        width: 100%;
    }

    /* .teamsTopBar{
        display: block;
    } */
    
    .tHide1{
        display: none;
    }
    .tHide2{
        display: block;
    }


    .startOnlSessBtn{
        display: none;
    }

    .startOnlSessBtn2{
        display: block;
    }

    .teamsDiv{
        display: block;
        height: 20vh;
    }

    .tNamePC{
        float: left;
    }


    /* profile */

    .coverPhotoCont{
        height: 25vh !important;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
    }

    .profilePictureCont{
        height: 130px !important;
        width: 130px !important;
        background-color: royalblue;
        border-radius: 50%;
        position: absolute;
        top: 15vh !important;
        left: 5%;
        border: 4px rgb(245, 247, 250) solid;
        overflow: hidden;
    }

    .profileInfoCont{
        position: absolute;
        top: 26vh !important;
        left: 45% !important; 
        display: flex;
        justify-content: space-between;
        width: fit-content;
        flex-direction: column;
        overflow: hidden;
    }

    .profileInfoCont h3{
        font-size: 13px;
    }

    .profileInfoCont button{
        display: block;
        width: max-content;
        font-size: 12px;
    }

    .profileInfoCont p{
        font-size: 12px;
    }


    .profileBody{
        display: flex;
        flex-direction: column;
        margin-top: 19vh;
        margin-bottom: 5vh;
       
    }

    .editAccountMobile{
        display: block;
        background-color: royalblue;
        height: fit-content;
        color: white;
        border: royalblue 1px solid;
        font-size: 12px;
        padding: 5px 20px;
        border-radius: 20px;
        position: absolute;
        right: 0px;
        margin-top: 5px;

    }


    .theClearJob{
        clear: both;
        
    }

    .profieAboutBody{
        width: 100% !important;  
        /* height: 40vh; */
        background-color: white;
        border-radius: 10px;
        box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
        overflow: hidden;
        padding-bottom: 14px;
    }

    .profileOverview{
        background-color: white;
        width: 100% !important;
        margin-left: 0 !important;
        padding: 20px;
        box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
        border-radius: 10px;
        margin-left: 0px;
        margin-top: 3vh;
        height: fit-content;
    }

    .profileComp{
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 0vh;
    }


    /* TASKS */

    .assTop p{
        font-size: 14px;
    }
    .taskCont3{
        display: none;
    }

    .taskCont1{
        width: 48%;
    }

    .taskCont2{
        width: 48%;
    }
    
    .theAssMainCont{
        display: block;
    }

    .assInstructorCont{
        width: 100%;
    }

    .taskItemsCont{
        height: 100vh;
        width: 100%;
        margin-top: 3vh;
    }

    /* CLIENTS */

    .clientsTop{
        margin-top: 5vh;
        display: block;
    }

    .clientLeft{
        width: 100%;
        margin-bottom: 3vh;
    }

    .clientRight{
        width: 100%;
    }

    .cliRight1, .cliRight2{
        padding: 10px;
    }

    .cliRight3{
        margin-bottom: 10vh;

    }
    
 
    /* FEE */
    .feelineGraph{
        display: block;
        height: max-content !important;  
    }

    .feeTopDiv{
        height: fit-content;
    }

    
    .feeItemsCont{
        display: block;
        justify-content:left;
    }


    .paymentsDivContainer{
        display: flex;
        flex-direction: column; 
    }


    .dChild22{ 
        width: 100% !important;
        height: 16vh;
        background-color: white;
        border-radius: 10px;
        float: none;
        margin-bottom: 3vh;
        box-shadow: rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        color: white;
    }


    .clientsComp{
        width: 100%;
        overflow: scroll;
    }

    .materialComp{
        width: 100% !important;  
        margin-left: 0px !important; 
        margin-right: 0px !important; 
        margin-bottom: 10px;

    }


    
}